/* DONT DELETE THIS FILE - NEEDED FOR RAINBOW EFFECT FOR Custom Branding */
@keyframes glowingRainbow {
  0% {
    color: red;
  }
  16% {
    color: orange;
  }
  32% {
    color: yellow;
  }
  48% {
    color: green;
  }
  64% {
    color: blue;
  }
  80% {
    color: indigo;
  }
  100% {
    color: violet;
  }
}

.glowing-rainbow-text {
  animation: glowingRainbow 3s linear infinite;
}