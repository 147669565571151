@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');


@layer components {
  .button {
    @apply w-full p-2 rounded text-white font-bold bg-gradient-to-r from-indigo-500 to-indigo-400 shadow
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  /* font-family: 'Nunito', 'Poppins', sans-serif; */
  background-color: #1a1a1a !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body::-webkit-scrollbar {
  display: none;
}

#date {
  color-scheme: dark;
}

#time {
  color-scheme: dark;
}

#rfs-btn {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-color: #E2E8F0;
}

.learn-more {
  display: flex;
  align-items: center;
  color: #2a1386;
  opacity: .8;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
}

.learn-more svg {
  margin-left: 5px;
  opacity: 1 !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}