.successFailMessage-component {
    text-align: center;
    border-radius: 6px;

    p {
        padding: 10px 5px;
    }

    .fail {
        border: 1px solid #cc1246;
        box-shadow: 0 0 10px #ed1e58;
        color: #cc1246;
    }

    .success {
        color: green;
        border: 1px solid green;
        box-shadow: 0 0 10px green;
    }
}