.inlineError {
    position: relative;
    text-align: left;
    font-size: 14px;
    display: flex;
    align-items: center;    

    .icon {
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 5px;
    }

    .text {
        display: inline-block;
    }
}