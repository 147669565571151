
.plansContainer {
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: space-evenly;
    max-width: 1400px;
    margin: auto;
    flex-wrap: wrap;
    // color: #fff;
    color: rgb(32, 35, 83);

    .pricing__table {
        display: block;
        padding: 20px;
        background: #ccc;
        margin: 10px;
        -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -moz-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -ms-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        border-radius: 10px;
        position: relative;
        flex: 1;

        @media (max-width: 1100px) {
            flex: calc(50% - 40px);
        }

        @media (max-width: 450px) {
            flex: 100%;
        }
    
        .icon {
            display: inline-block;
            padding: 20px;
            background: #fff;
            text-align: center;
            line-height: 70px;
            font-size: 2.2em;
            margin: 20px auto 10px auto;
            border-radius: 50px;
            -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
        }
    }

    .pricing__table:hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
        z-index: 100;
    }

    .heading {
        text-align: center;
        text-transform: capitalize;
        font-weight: lighter;
        font-size: 1.5em;
        font-weight: 700;
    }
    
    .service__price {
        text-align: center;
        font-size: 3em;
    }
    
    .service__price .currency {
        font-size: 0.6em;
        margin-left: -10px;
    }
    
    .order__button {
        border: none;
        display: block;
        width: 100%;
        height: 40px;
        margin: 40px auto 10px auto;
        border-radius: 10px;
        -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        font-weight: 500;
        color: white;
    }
    
    .order__button:focus {
        outline: none;
    }
    
    .plans-toggle.react-toggle--checked .react-toggle-track {
        background-color: #ab199f;
      }
    
    .plans-toggle.react-toggle--checked:hover {
        background-color: #ab199f;
    }
}
