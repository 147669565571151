#contact-us {
  text-align: left;
  width: 100%;
  margin: 40px auto;

  .title {
    color: #6366f1;
    font-weight: bold;
    font-size: 20px;
  }

  .description, .entice {
    color: #101010;
    opacity: .8;
    font-weight: bold;
    margin-top: 10px;
    font-size: 35px;
  }

  .entice {
    margin-top: 5px;
    font-size: 15px;
    opacity: .6;
  }

  .contact-us-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    .form-wrapper {
      width: 100%;
      max-width: 600px;

      @media (max-width: 500px) {
        // padding: 0 20px 20px 0;
        padding: 0 20px 20px 20px;
      }
    }

    svg {
      width: 30%;

      @media (max-width: 500px) {
        width: 80%;
        display: none;
      }
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }

    input, textarea, button {
      padding: 0 25px;
      border: 1px solid rgba(116, 126, 136, 0.2);
      height: 50px;
      border-radius: 5px;
      width: 100%;

      &:first-child {
        margin-right: 25px;
      }
    }

    textarea {
      padding-top: 15px;
      margin-top: 20px;
      height: 230px;
      resize: none;
    }

    button {
      max-width: 130px;
      margin-top: 15px;
      background-color: #6366f1;
      color: white;
    }
  }
}