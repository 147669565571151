#admin-analytic-metric {
  height: 100vh;
  // width: 100vw;
  background-color: #6366f1;
  color: white;
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;

  .title {
    font-size: 44px;
    font-weight: bold;
    margin: 20px 0 40px 0;
    letter-spacing: 1px;
  }

  .analytic-title {
    font-size: 16px;
    font-weight: bold;
  }

  .analytic-value {
    font-size: 24px;
    font-size: 60px;
    font-weight: bold;
  }

  .grid-container {
    max-width: 1000px;
    margin: auto;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
  }
  
  .grid-item {
    border: 1px solid white;
    border-radius: 8px;
    padding: 10px;
    font-size: 30px;
    text-align: center;
  }
}